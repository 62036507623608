import React from 'react';
/* Select tag import*/
import $ from 'jquery';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component'
import { ToastContainer, toast } from 'react-toastify';
const Spinner = loadable(() => import('./spiner'));
const SectionSpiner = loadable(() => import('./sectionspinner'));
const LoginSignuppopup = loadable(() => import('./loginSignuppopup'));
const Slider = loadable(() => import('react-slick'));
const Requestsubmit = loadable(() => import('./requestsubmit'));
const Socialsharing = loadable(() => import('./socialsharing'));
const Testdrive = loadable(() => import('./testdrive'));


class Topcars extends React.PureComponent {


  constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: [],
          count:0,
          slides:0,
          vehicleresponseError:null,
        };
         this.next = this.next.bind(this);
         this.previous = this.previous.bind(this);
				 this.handleSave = this.handleSave.bind(this)
		     this.handleShare = this.handleShare.bind(this)
		     this.getMonthly = this.getMonthly.bind(this)
      }

      	getMonthly=price=>{
var downpayments=price/5; 
 var princ = price-downpayments; //Get the input principal amount
  var intr = 1.9/100/12; //Get the input interest amnount 
  var term = 60; //get the number of years to payback the loan
 var value = princ * intr / (1 - (Math.pow(1/(1 + intr), term)));
 return value.toFixed(2);
			}
handleVehicleData= handleVehicleData=>{
    			  var self=this;
                  self.setState({VehicleDataInfo:handleVehicleData})
                  localStorage.setItem("VehicleDataInfo", JSON.stringify(handleVehicleData));
                  
                }
      next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
	componentDidMount() {
         var self = this;
        
       $.post(
        "https://www.shopsmartautos.com/withbb.php",
        {top_deals_load:"top_deals_load"
   },
        function(data) {

        var getResponse=JSON.parse(data);
        
          self.setState({ items: getResponse.listings });
          self.setState({ isLoaded: true });
        
      });
        
        
        
        
         navigator.geolocation.getCurrentPosition(function(position) { 
          self.setState({latitude: position.coords.latitude,longitude: position.coords.longitude,items: []});
        
       $.post(
                  "https://www.shopsmartautos.com/withbb.php",
                  {lattd:position.coords.latitude,longtd:position.coords.longitude,top_deals_load:"top_deals_load"
             },
                  function(data) {

                  var getResponse=JSON.parse(data);
                  if(getResponse.num_found!==0)
                  {
                    self.setState({ items: getResponse.listings });
                    self.setState({ isLoaded: true });
                  }else
                  {
                    self.setState({ vehicleresponseError:'No best deals are available to your location' });
                    
                      $.post(
                            "https://www.shopsmartautos.com/withbb.php",
                            {lattd:40.94623,longtd:-73.069777,top_deals_load:"top_deals"
                       },
                            function(data) {
                            var getResponse=JSON.parse(data);
                             
                                  self.setState({ items: getResponse.listings });
                                  self.setState({ isLoaded: true });
                                
                            }
                         );

                  }
                }
               ); 
       }, (error)=>{


           $.post(
                            "https://www.shopsmartautos.com/withbb.php",
                            {lattd:40.94623,longtd:-73.069777,top_deals_load:"top_deals"
                       },
                            function(data) {
                            var getResponse=JSON.parse(data);
                              if(getResponse.num_found!==0)
                                {
                                  self.setState({ items: getResponse.listings });
                                
                                  self.setState({ vehicleresponseError:null});
                                  self.setState({ isLoaded: true });
                                }else
                                { 

                                  self.setState({ vehicleresponseError:'No best deals are available to your location' });
                                  self.setState({ isLoaded: true });
                                }
                            }
                         ); 

                  });
                }


                				handleSave(item){
						var self=this;
            console.log('asdasd');
						var getUToken=localStorage.getItem('user_access_token');
						var UserDataSSA=JSON.parse(localStorage.getItem('UserDataSSA'));
						if(getUToken!==null && getUToken!==undefined && getUToken!=='')
						{
								if($('#'+item.vin).hasClass('fa-heart'))
								{
									
									
										$.post("https://www.shopsmartautos.com/user.php",{'unsaved':'unsaved',vehicleData:item,user_access_token:getUToken,user_id:UserDataSSA.id},function(data) {
											
									$('#'+item.vin).addClass('fa-heart-o');
									$('#'+item.vin).removeClass('fa-heart');
									
									toast.success("You unsaved "+item.heading,{
      										toastId: "favrates",autoClose: 1500 
    										});
											
											})
         
									
								}else
								{
									$.post("https://www.shopsmartautos.com/user.php",{'save_car':'save_car',vehicleData:item,user_access_token:getUToken,user_id:UserDataSSA.id},function(data) {
									$('#'+item.vin).removeClass('fa-heart-o');
									$('#'+item.vin).addClass('fa-heart');
									
									toast.success("Great Choice \n You saved "+item.heading,{
      										toastId: "favrates",autoClose: 1500 
    										});
											
											})
								}
								
						}else
						{
              console.log('svaed');
								localStorage.setItem('saveVehicleData',JSON.stringify(item));
								localStorage.setItem('saveStatus',true);
								$('.login').show();
								$('.login').css({'opacity':1,'background':'#20252a7a'});
						}

					
				}
	handleShare(item)
	{
		var url=window.location.href;
					var self=this;
					self.setState({url_key:url+'car-details/?id='+item.id})					
	}

  render() {

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
      const { error, isLoaded, items,slides } = this.state;
             


      if (error) {
          return <div> {this.state.vehicleresponseError}</div>;
        }else {
  return   <React.Fragment> <section id="products" className="pb-5 mt-4">
       
          <Testdrive/>
  				<Requestsubmit/>
  				<LoginSignuppopup/>
  				<Socialsharing url_key={this.state.url_key}/> 
        
        <div  className="carousel slide carousel-multi-item" data-ride="carousel">


          {this.state.items.length !==0 && isLoaded  ? (
          <div className="controls-top">
            <button type="button" className="btn-floating primary-color waves-effect waves-light left sp" onClick={this.previous}>
              <i className="fa fa-chevron-left" />
            </button>
            <button className="btn-floating primary-color waves-effect waves-light right sp" onClick={this.next}>
              <i className="fa fa-chevron-right" />
            </button>

          </div>
          ):('')}
          
<small className="text-center h1-responsive dark-grey-text font-weight-bold my-5 text-center">{this.state.vehicleresponseError !==null ? this.state.vehicleresponseError :('')}</small>
        <h3 className=" pl-2 pb-4">Best Deals Of The Day</h3>
           {!isLoaded ? (<SectionSpiner/>):(
          <Slider ref={c => (this.slider = c)} {...settings}>

           {this.state.items.map(item=>(
          <div className="col-lg-12 loadmore ribbon "  key={item.id}>
									{item.conditons ?(<span class="ribbon1"><span>{item.conditons}</span></span>):(<span class="ribbon1"><span>Recommended</span></span>)
									    
									}
									<div className="card">
									 <div className="">
						<Link target="_blank" to={"/car-details/?id="+item.id}>
           
            <img className="d-block w-100 h-300 lazyload" height="338px" width="220px" key={item.id} data-src={item.media!==undefined  &&  item.media.photo_links!==undefined  ? (item.media.photo_links[0]):('../media/default-car.png')} alt="car" />
          </Link>
        <div className="card-body pt-0">
          
<div className="row pr-0 pb-1">
<div className="col-lg-6  text-left">
 <div className="badge-overlay">
		  
<span className="top-left badge oranges">Certified</span>

</div>
</div>
<div className="col-lg-6 text-right">
<a href="javascript:void(0)" data-toggle="modal" data-target="#share"  onClick={() =>{this.handleShare(item)}}><i className="shares fa fa-share"></i></a>
<a href="javascript:void(0)"  data-toggle="modal" data-target="#centralModalInfoDemo" onClick={() =>{this.handleVehicleData(item)}} ><span className="tesdrives"><LocalTaxiIcon/></span></a>
<a href="javascript:void(0)" onClick={() =>{this.handleSave(item)}}>
<i id={item.vin} className="fa fa-heart-o favrates"></i></a></div>
</div>
	
		   <div className="alert alert-success col-lg-12"><i className="fa fa-check-circle" aria-hidden="true"></i> Authorized {item.dealer.name}
          </div>
        
		<div className="row">
	<div className="col-lg-8 text-l">
	<strong>
		{
				item.price!=undefined
				  ?
				 	'$'
				  :
				  item.prices !=null
				  ?
				  '$'
				  :
				   ''

					}
				{
            	  item.prices!=undefined
				  ?
				 	item.prices.toLocaleString()
				  :
				  item.price !=null
				  ?
				  item.price.toLocaleString()
				  :
				   'NA'
				}
			</strong><small className="smalls"> <a href={"/car-details/?id="+item.id}>${
            	  item.prices!=undefined
				  ?
					 this.getMonthly(item.prices)
				  :
				  item.price !=null
				  ?
				  this.getMonthly(item.price)
				  :
				   'NA'
				}
				/mo est*	</a></small>
              
	
	</div>	
<div className="col-lg-4 text-right"> <p>{item.miles ? item.miles:0} Miles</p>
	</div></div>
		  

          <h6 className="" ><strong>{item.heading}</strong></h6>
		  <div className="row">
          <div className="col-lg-6 pl-3 pr-0"> <small>{item.ratting ? ("KBB.com Expert Ratings"):('')}</small><small><div dangerouslySetInnerHTML={{ __html: item.ratting ?(item.ratting):('') }} />{item.value ?(<strong>{item.value}/5</strong>):('')}</small></div>
		  <div className="col-lg-6 pr-2 text-right"> <small>{item.dealer.name} ,{item.dealer.city},{item.dealer.state}({item.dist?(item.dist):('few')}) Miles</small></div>
		  
		  <div className="col-lg-6">{item.dealer.phone!==undefined && item.dealer.phone!==null ? (<div><i className="fa fa-phone"></i> <a href={'tel:'+item.dealer.phone}>{item.dealer.phone}</a></div>):('')}</div>
		  <div className="col-lg-6"><i className="fa fa-envelope"></i> <a href="#" data-toggle="modal" data-target="#request" onClick={() =>{this.handleVehicleData(item)}} >Request information</a></div>
		 
		  </div>
		  </div>
        </div>
									</div>
									</div>
									))}
        </Slider>
)}
        </div>
      </section>  </React.Fragment>
  ;
  }}
}
export default React.memo(Topcars);