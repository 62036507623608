import React from 'react';


class OurShopers extends React.PureComponent {
  render() {
    return   <React.Fragment><div className="textwidget ourshopers custom-html-widget">
   <style dangerouslySetInnerHTML={{__html: "#tm-top-d {padding-top: 12px;}.a-single.a-17 {text-align: center;}.uk-panel.widget_custom_html {text-align: center;}" }} /><h2 className="text-center " style={{textAlign: 'center'}}>Our Shoppers Become Buyers</h2>
   <div className="col-lg-12"><p style={{textAlign: 'center'}}>ShopSmartAutos.com prides itself on offering an unprecedented inventory of over 9 million vehicles pulled daily from national dealer feeds and private parties. Punch in the car you are looking for and see how many offers there are on that vehicle and find them within driving distance of your home, or delivered to your doorstep.</p></div>
</div>  </React.Fragment>;
  }
}
export default React.memo(OurShopers);