import React from 'react';
import Slider from "react-slick";
import Searchbarhome from "./Searchbarhome";
import OurShopers from "./OurShopers";
import BrowseByBodyStyle from "./BrowseByBodyStyle";
import BrwoseByPrice from "./BrwoseByPrice";
import BrowseByBrand from "./BrowseByBrand";
import DreamCar from "./DreamCar";
import Footer from "./Footer";
import Ads from "./ads";
import Topcars from "./Topcars";
import Newsletter from "./newsletter";
import SEO from 'react-seo-component';


class Maincontent extends React.Component {
  

  
   render(){   
    var settings = {
      dots:true,
      infinite: true,
      speed: 500,
      autoplay:true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
         
              <SEO
            title={'Home-shopsmartautos'}
            titleTemplate={'shopsmartautos'}
            titleSeparator={'-'}
            description='ShopSmartAutos One Stop Solution for Automobile Enthusiasts'
            image={'https://www.shopsmartautos.com/media/Depositphotos_173886668_l-2015.webp'}
            pathname={'https://www.shopsmartautos.com'}
            siteLanguage={'en-US'}
            siteLocale={'en-US'}
            twitterUsername={'shopsmartautos'}
/>
              <div className="containers">
                 <Searchbarhome/>
                 <div className="sliderss">
        <Slider {...settings } autoplaySpeed={15000}>
          
          <div
          className="slick_custom "> 
          <div className="slider-content  " >
           <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4 inner3">
                <h1>We Sweep The Country To Share The Best Deals</h1>
                 <a className="btn btn-warning" href="/used-cars">view now</a>       
              </div>
              
      <link rel="preload" as="image" href="../media/newimages/specialdeale1.jpg" />
            <img data-src="../media/newimages/specialdeale1.jpg"  height="100%" width="100%" className="lazyload"/>
           </div>
           </div>
          <div
          className="slick_custom">
           <div className="slider-content  "  >
             <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4 inner3">
                <h1>The Only Way To Buy A Car</h1> 
                <h6>Once you find it we’ll make it ready for pick up or delivery to your door</h6>
                 <a className="btn btn-warning" href="/new-cars">view now</a>      
              </div>
              
      <link rel="preload" as="image" href="../media/newimages/slider2specialdeal.jpg" />
        <img data-src="../media/newimages/slider2specialdeal.jpg" height="100%" width="100%" className="lazyload"/>
            
           </div>
           </div>
          <div
          className="slick_custom"
          > <div className="slider-content  "  >
            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4 inner3">
                <h1>Private Party Cars</h1>
                <h6>Your friends and neighbors have great deals too</h6> 
                 <a className="btn btn-warning" href="/private-party-cars">view now</a>      
              </div>
              <link rel="preload" as="image" href="../media/newimages/slide4min.JPG" />
           <img data-src="../media/newimages/slide4min.JPG" height="100%" width="100%" className="lazyload"/>
            
           </div>
           </div>
           <div
          className="slick_custom"
          > <div className="slider-content  " >
            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4 inner3">
                <h1>Buying Or Selling Your Pre Owned</h1>  
                <h6>Black Book, America’s trusted advisor is here to help.</h6>
                <a className="btn btn-warning" href="/used-cars">view now</a>     
              </div>
              
              <link rel="preload" as="image" href="../media/newimages/slider6.jpg" />
          <img data-src="../media/newimages/slider6.jpg" height="100%" width="100%" className="lazyload"/>
            
               
           </div>
           </div>
           <div
          className="slick_custom"
          > 
        <div className="slider-content  " >
         <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4 inner3">
                <h1>Certified Pre Owned With All The Benefits</h1> 
                <h6>Many are still under manufacturer warranties</h6>
                 <a className="btn btn-warning" href="/certified-cars">view now</a>      
              </div>
              
              <link rel="preload" as="image" href="../media/newimages/slider5min.jpg" />
          <img data-src="../media/newimages/slider5min.jpg" height="100%" width="100%" className="lazyload"/>              
           </div>
           </div>
          <div
          className="slick_custom"
          > 
        <div className="slider-content  " >
          <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4 inner3">
                <h1>Value my Car</h1> 
                <h6>Based on Black Book® Values</h6>
                 <a className="btn btn-warning" href="/value-my-car">view now</a>      
              </div>
        <img data-src="../media/newimages/slider8.JPG" height="100%" width="100%" className="lazyload"/>
            
              
           </div>
           </div>
           <div
          className="slick_custom"> 
        <div className="slider-content  " >
        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4 inner3">
                <h1>The Right Car With The Right Review</h1> 
                <h6>We’ve got 10,000 genuine reviews and Black Book ® Values</h6>
                 <a className="btn btn-warning" href="/car-reviews">view now</a>      
              </div>
           <img data-src="../media/newimages/slider1min.jpg" height="100%" width="100%" className="lazyload"/>
              
              
           </div>
           </div>
           </Slider>
        </div>
        </div>
      <div className="container mt-5">
           
      <Ads/>
         <Topcars/>
       <OurShopers/>

       <BrowseByBodyStyle/>
        
       <BrwoseByPrice/>
       <BrowseByBrand/>
       <DreamCar/>
       </div>
       <Footer/>
        </div>
       

           

     
    );
  }
}
export default Maincontent;