import React from 'react';

import Routes from './routes/Routes'; // Import a component from another file

function App() {
  return (
    <div>
        <Routes/>
    </div>
  );
}
export default App;
