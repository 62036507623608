import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
class BrowseByBrand extends React.PureComponent {

         constructor(props) {
          super(props);
          this.state = {
            error: null,
            isLoaded: false,
            Brands: [],
            valueCarType:null,
            branderror:null,
          };
          this.handleChangeBrand = this.handleChangeBrand.bind(this)
        
        }
         componentDidMount (){
               var self=this;

               
               $.post(
                "https://www.shopsmartautos.com/react_apibb.php",
                {
                   "LoadBrands":"LoadBrands",car_type:'new'
               },
                function(data) {
                var getResponse=JSON.parse(data);
                
                  self.setState({ Brands: getResponse.facets.make });
                  self.setState({ isLoaded: true });
                  self.setState({ branderror: null });
                
                  
                })
                 navigator.geolocation.getCurrentPosition(function(position) { 
          self.setState({latitude: position.coords.latitude,longitude: position.coords.longitude,});
               
               $.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                     "LoadBrands":"LoadBrands",latitude:position.coords.latitude,longitude:position.coords.longitude,car_type:'new'
                 },
                  function(data) {
                  var getResponse=JSON.parse(data);
                  if(getResponse.num_found!==0)
                  {
                    self.setState({ Brands: getResponse.facets.make });
                    self.setState({ isLoaded: true });
                    self.setState({ branderror: null });
                  }else
                  {

                    self.setState({ isLoaded: true });
                    self.setState({ branderror: 'Brands are not available to this location.' });
                  }
                    
                    
                  }
               );
                }, (error)=>{
                  $.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                     "LoadBrand":"LoadBrand",latitude:40.94623,longitude:-73.069777,car_type:'new'
                 },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Brands: getResponse.facets.make });
                     self.setState({ branderror: null });
                    self.setState({ isLoaded: true });
                    
                  }
               );

                });
         }
           handleChangeBrand= (handleGetRange)=>{
            var self=this;
           
            
   handleGetRange.persist();
   self.setState({valueCarType:handleGetRange.currentTarget.value});
          
            navigator.geolocation.getCurrentPosition(function(position) { 
           
          self.setState({latitude: position.coords.latitude,longitude: position.coords.longitude});
           
 
            $.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                     "LoadBrand":"LoadBrand",latitude:position.coords.latitude,longitude:position.coords.longitude,car_type:self.state.valueCarType
                 },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Brands: getResponse.facets.make });
                    self.setState({ isLoaded: true });
                    
                  }
               );
            
          }, (error)=>{
  
            self.setState({latitude:40.94623,longitude:-73.069777});
           
                  $.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                     "LoadBrand":"LoadBrand",latitude:40.94623,longitude:-73.069777,car_type:self.state.valueCarType
                 },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Brands: getResponse.facets.make });
                    self.setState({ isLoaded: true });
                    
                  }
               );

          })
         }

  render() {
   const  Brands  = this.state.Brands;
    return <div className="textwidget custom-html-widget">
   <h2 className="uk-text-center uk-margin-small-bottom">Browse By Brand</h2>
    <ul className="nav md-tabs nav-justified grey lighten-3 mx-0" role="tablist">
        <li className="nav-item">
          <button  value="new" onClick={this.handleChangeBrand} className="nav-link dark-grey-text font-weight-bold active" data-toggle="tab" href="#panel5" role="tab" aria-selected="true"> New Cars</button>
        </li>
        <li className="nav-item">
          <button value="used" onClick={this.handleChangeBrand} className="nav-link dark-grey-text font-weight-bold" data-toggle="tab" href="#panel6" role="tab" aria-selected="false">
            Used Cars
          </button>
        </li>
        <li className="nav-item">
          <button value="certified" onClick={this.handleChangeBrand} className="nav-link dark-grey-text font-weight-bold" data-toggle="tab" href="#panel7" role="tab" aria-selected="false">
            Certified Cars</button>
        </li>
      </ul>
      <div className="tab-content px-0">
        {/* Panel 1 */}
        <div className="tab-pane fade in active show" id="panel5" role="tabpanel">
          <div className="card">  
          <div className="col-lg-12">
              {this.state.branderror !==null ? this.state.branderror : ('')}

          <ul className="row">
            {Object.keys(Brands).map(key=>(
              <li className="col-lg-4 customAnchore" key={Brands[key].item}>
              <Link  target="_blank" to={"/new-cars/?Makesearch="+Brands[key].item}> {Brands[key].item}<small>({Brands[key].count})</small></Link></li>
               ))}
               </ul>
         
          </div>
          </div>
        </div>
        {/* Panel 1 */}
        {/* Panel 2 */}
        <div className="tab-pane fade" id="panel6" role="tabpanel">
         
            <div className="card">
          <div className="col-lg-12">
              {this.state.branderror !==null ? this.state.branderror : ('')}

          <ul className="row">
            {Object.keys(Brands).map(key=>(
               <li className="col-lg-4  customAnchore" key={Brands[key].item}>
               <Link   target="_blank" to={"/used-cars/?Makesearch="+Brands[key].item}>{Brands[key].item}<small>({Brands[key].count})</small></Link></li>
               ))}
               </ul>
         
          </div>
          </div>  
        </div>
        {/* Panel 2 */}
        {/* Panel 3 */}
        
        <div className="tab-pane fade" id="panel7" role="tabpanel">
           <div className="card">
          <div className="col-lg-12">
             
  {this.state.branderror !==null ? this.state.branderror : ('')} 
          <ul className="row">
         
            {Object.keys(Brands).map(key=>(
               <li className="col-lg-4 customAnchore" key={Brands[key].item}>
               <Link  target="_blank" to={"/certified-cars/?Makesearch="+Brands[key].item}> {Brands[key].item}<small>({Brands[key].count})</small></Link></li>
               ))}
               </ul>
         
          </div>
          </div>
          </div>
        {/* Panel 3 */}
        
      </div>
</div>

  }
}
export default React.memo(BrowseByBrand);