import React from 'react';
import loadable from '@loadable/component'
import $ from 'jquery';
import {Link }from 'react-router-dom';

const Slider = loadable(() => import('react-slick'));
const SectionSpiner = loadable(() => import('./sectionspinner'));
class DreamCar extends React.PureComponent {
   constructor(props){
        super(props)
        this.state ={
                posts: null,
                isLoaded:false,
                error:false,
                loading:false,
                    }
                 this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.time_ago = this.time_ago.bind(this);
                        }



                         time_ago(time) {

  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  var time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  var seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1;

  if (seconds == 0) {
    return 'Just now'
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  var i = 0,
    format;
  while (format = time_formats[i++])
    if (seconds < format[0]) {
      if (typeof format[2] == 'string')
        return format[list_choice];
      else
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
  return time;
}
toShortFormat (date){
  var t = date.split(/[- :]/);

// Apply each element to the Date function
var d = new Date(parseInt(t[0]), parseInt(t[1])-1, parseInt(t[2]), parseInt(t[3]), parseInt(t[4]), parseInt(t[5]));
var anyDate = new Date(d);
    var monthNames =["Jan","Feb","Mar","Apr",
                      "May","Jun","Jul","Aug",
                      "Sep", "Oct","Nov","Dec"];
    
    var day = anyDate.getDate();
    
    var monthIndex = anyDate.getMonth();
    var monthName = monthNames[monthIndex];
    
    var year = anyDate.getFullYear();
    
    return `${day} ${monthName}, ${year}`;  
}
 componentDidMount (){
  var self=this;
  $.post(

                  "https://blog.shopsmartautos.com/react_apibb.php",
                  {

                    "GET_POST":"GET_POST"   },
                  function(data) {
                    var getResponse=JSON.parse(data);
                    self.setState({ posts: getResponse,isLoaded: true  });
                    
                  }
                  ); 
    }   
 next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {

       var settings = {
      dots: true,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  

              
 console.log('render');
     const { error, isLoaded, posts,slides,loading } = this.state;
     if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <SectionSpiner/>;
        } else {
            const posts  = this.state.posts;

              var getSlides= posts.map(function(item, il)
                {
                  
              })
    return   <React.Fragment><article className="container">
            <h1 className="uk-article-title" />
            <h2 style={{textAlign: 'center'}}>Your Dream Car Is Here</h2><p style={{textAlign: 'center'}}>You’ll see the full available inventory from top automotive sales sites and dealers across the country. Once you find the vehicle you’re looking for, you can narrow or open your search zip code by zip code</p>
            <hr />
   <div className="feature-blog w3eden">
      <div className="">
         <div className=""><h2 className="feature-title" style={{textAlign: 'center'}}>Our Story</h2></div>
         <p>&nbsp;</p>
      </div>
   </div>

      <div className="row">
<div className="controls-top">
            <button type="button" className="btn-floating primary-color waves-effect waves-light" onClick={this.previous}>
              <i className="fa fa-chevron-left" />
            </button>
            <button className="btn-floating primary-color waves-effect waves-light" onClick={this.next}>
              <i className="fa fa-chevron-right" />
            </button>

          </div>
        
<Slider ref={c => (this.slider = c)} {...settings}>
   {Object.values(posts).map(keys=> (
<div className="col-lg-12" key={keys.ID}>
        <div className="card">
        <div className="author p-3">

          <img data-src="../media/author.jpg" className="rounded-circle mr-3 lazyload" height="50px" width="50px" alt="avatar"/>
         <div>
         <strong className="white-text">Richie Bello</strong>
         <p className="white-text">{this.time_ago(keys.post_date)}</p>
        </div>
        </div>
              <div className="">
              <div className="filler" />
 <img data-src={keys.featured[0]} height="178" width="340" className="lazyload"/>
     <a>
                  <div className="mask waves-effect waves-light"></div>
                </a>
              </div>
             
              <div className="card-body">               
                <h4 className="card-title">{keys.post_title}</h4>
                <hr/>
                
                <p className="card-text"><div className="post_content" dangerouslySetInnerHTML={{ __html:keys.post_content.slice(0, 205)}}/>...</p>
                <Link to="/feature-blog" className="black-text">
                  <h5>Read more <i className="fa fa-chevron-right"></i></h5>
                </Link>
              </div>
          
         </div>
         </div>
   ))}        </Slider>
         </div>
</article>  </React.Fragment>;
  }}
}
export default React.memo(DreamCar);