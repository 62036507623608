import React from 'react';
import $ from 'jquery';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';


import { ToastContainer, toast } from 'react-toastify';

class loginSignuppopup extends React.PureComponent {

         constructor(props) {
          super(props);
          this.state = {
            error: null,
            isLoaded: false,
            valueCarType:null,
            emailNewsvarter:null,
            responseText:null,
          VehicleDataInfo:null,
           userEmail:null,
          Userpassword:null,
          TC:null,
          VehicleDataInfo:null,
          errors:[],
          errorss:[],
          rclogin:null,
          userEmaillogin:null,
          userPasswordlogin:null,
          Remebercheck:null,
          

          };

        
        this.responseGoogleLogin = this.responseGoogleLogin.bind(this)
         this.responseFacebooks = this.responseFacebooks.bind(this)
         this.hideModal = this.hideModal.bind(this)
         this.Form2Validations = this.Form2Validations.bind(this)
         this.loginvalidations = this.loginvalidations
         .bind(this)
         this.UserRegsiter = this.UserRegsiter.bind(this)
         this.handlelogin = this.handlelogin.bind(this)
        }
        
         hideModal(){
             $('#login').hide();
		     $('#login').css('opacity',0);
          $('.login').hide();
		     $('.login').css('opacity',0);
         }
       
          
 responseGoogleLogin = (response) => {
   var self=this;
    console.log(response.profileObj);
        $.post(
                  "https://www.shopsmartautos.com/user.php",
                  {
                     "user_singup_social":"user_singup_social",user_name:response.profileObj.name,user_email:response.profileObj.email,social_id:response.profileObj.googleId
                 },
                  function(data) {
                    var getData=JSON.parse(data);
                    var data = getData;

                    $('.login').hide();
                     toast.success("Hi, "+response.profileObj.name+'\n Welcome to Shop Smart Autos',{
      										toastId: "favrates",autoClose: 3000 
    										});
                    self.setState({access_token:getData.data.user_data.access_token,user_data:getData.data.user_data});
                    localStorage.setItem('user_access_token',getData.data.user_data.access_token);
                    localStorage.setItem('UserDataSSA',JSON.stringify(getData.data.user_data));
                    var getSaveStatus=localStorage.getItem('saveStatus');
                    var item=JSON.parse(localStorage.getItem('saveVehicleData'));
                    if(getSaveStatus){
                        if($('#'+item.vin).hasClass('fa-heart'))
                                  {	
                                  $.post("https://www.shopsmartautos.com/user.php",{'unsaved':'unsaved',vehicleData:item,user_access_token:getData.data.user_data.access_token,user_id:getData.data.user_data.id},function(data) {
                                        
                                    $('#'+item.vin).addClass('fa-heart-o');
                                    $('#'+item.vin).removeClass('fa-heart');
                                    
                                    toast.success("You unsaved "+item.heading,{
                                            toastId: "favrates",autoClose: 1500 
                                          });
                                        
                                        })	
                                  }else
                                  {
                                    $.post("https://www.shopsmartautos.com/user.php",{'save_car':'save_car',vehicleData:item,user_access_token:getData.data.user_data.access_token,user_id:getData.data.user_data.id},function(data) {
                                    $('#'+item.vin).removeClass('fa-heart-o');
                                    $('#'+item.vin).addClass('fa-heart');
                                    
                                    toast.success("Great Choice \n You saved "+item.heading,{
                                            toastId: "favrates",autoClose: 1500 
                                          });
                                        
                                        })
                                  }
                    }
                    window.location.href='/';
                                    }
                 )
  
}
responseFacebooks = (response) => {
   var self=this;
    console.log(response);
        $.post(
                  "https://www.shopsmartautos.com/user.php",
                  {
                     "user_singup_social":"user_singup_social",user_name:response.name,social_id:response.id
                 },
                  function(data) {
                    var getData=JSON.parse(data);
                    console.log(getData);

                    

                        self.setState({access_token:getData.data.user_data.access_token,user_data:getData.data.user_data});
                    localStorage.setItem('user_access_token',getData.data.user_data.access_token);
                    localStorage.setItem('UserDataSSA',JSON.stringify(getData.data.user_data));
                    var getSaveStatus=localStorage.getItem('saveStatus');
                    var item=JSON.parse(localStorage.getItem('saveVehicleData'));
                                       
                  }
                 ).done(function(data){
                   var getData=JSON.parse(data);
                   $('.login').hide();
                     toast.success("Hi, "+getData.data.user_data.user_name+'\n Welcome to Shop Smart Autos',{
      										toastId: "favrates",autoClose: 3000 
    										});
            setInterval(function(){ window.location.href='/';}, 4000)
            })
  
}
// regsiter
            userEmail= userEmail=>{
              var self=this;
              userEmail.persist();
              self.setState({userEmail:userEmail.target.value});

            }
              userPassword= userPassword=>{
              var self=this;
              userPassword.persist();
              self.setState({Userpassword:userPassword.target.value});
            }
              userTC= userTC=>{
              var self=this;
              userTC.persist();
              self.setState({TC:userTC.target.value !==0 ? userTC.target.value:null});
            }
            
            
            //  user login
            userEmaillogin= userEmail=>{
              var self=this;
              userEmail.persist();
              self.setState({userEmaillogin:userEmail.target.value});

            }
            userPasswordlogin= userPasswords=>{
              var self=this;
              userPasswords.persist();
              console.log(userPasswords.target.value);
              self.setState({userpasswordlogin:userPasswords.target.value});

            }
            Remebercheck= userTC=>{
              var self=this;
              userTC.persist();
              self.setState({rclogin:userTC.target.value !==0 ? userTC.target.value:null});
            }
            
            UserRegsiter= userTC=>{
              var self=this;
              var email=self.state.userEmail;
              var Userpassword=self.state.Userpassword;
              var TC=self.state.TC;
              if(this.Form2Validations())
                {
                  $.post(
                  "https://www.shopsmartautos.com/user.php",{'user_register':'true','password':Userpassword,'user_email':email},
                      function(data) {
                        var getData=JSON.parse(data);
                          console.log(getData);
                          if(getData.error_code==="VERIFY_ACCOUNT")
                          {
                              window.location.href='/verify-email/?id='+btoa(getData.data.user_data.id)
                          }else
                          {
                             toast.success("You are already Register. Please login. ",{
                                      toastId: "verify",autoClose: 1500 
                              });
                          }

                      });
                }
            }

            handlelogin= userTC=>{
              var self=this;
              var userEmaillogin=self.state.userEmaillogin;
              var Userpasswordlogin=self.state.userpasswordlogin;
              var TC=self.state.TC;
              if(this.loginvalidations())
                {
                $.post(
                  "https://www.shopsmartautos.com/user.php",{'user_login':'true','password':Userpasswordlogin,'user_email':userEmaillogin},
                      function(data) {
                        var getData=JSON.parse(data);
                          
                          if(getData.error_code==="VERIFY_ACCOUNT")
                          {
                            toast.success("You are not verified yet Please verify first.",{
                                      toastId: "verify",autoClose: 1500 
                              });
                              window.location.href='/verify-email/?id='+btoa(getData.data.user_data.id)
                          }else if(getData.error_code==="USER_NOT_FETCHED")
                          {
                             toast.success("Your are not our user, Register again.",{
                                      toastId: "verify",autoClose: 1500 
                              });
                          }else
                          {
                            toast.success("Hi, "+getData.data.user_data.user_email+' Welcome to shop Smart Autos',{
                                      toastId: "verify",autoClose: 1500 
                              });
                            localStorage.setItem('user_access_token',getData.data.user_data.access_token);
                        localStorage.setItem('UserDataSSA',JSON.stringify(getData.data.user_data));
                        window.location.href='/';
                          }

                      });
                }
            }

            Form2Validations=getValidate=>{
              var self=this;
              var email=self.state.userEmail;
              var Userpassword=self.state.Userpassword;
              var TC=self.state.TC;
              var Messages={};
              if(email==null)
                {
                
                Messages['email']='Please fill up the email!';
                self.setState({errors:Messages});
                return false;
                
                }else if(Userpassword==null)
                { 
            
                Messages['userpassword']='Please fill the password !';
                self.setState({errors:Messages});
                return false;
            
                }else
                {
                    self.setState({errors:''});
                    return true;
                }

            }

            loginvalidations=getValidate=>{
                var self=this;
                var email=self.state.userEmaillogin;
                var Userpassword=self.state.userpasswordlogin;
                console.log(Userpassword);
                var TC=self.state.Remebercheck;
                var Messages={};
                    if(email==null)
                    {

                      Messages['emaillogin']='Please fill up the email!';
                      self.setState({errorss:Messages});
                      return false;
                    }else if(Userpassword==null)
                    { 
                      Messages['userpasswordlogin']='Please fill the password!';
                      self.setState({errorss:Messages});
                      return false;
                    }else
                    {
                      self.setState({errorss:[]});
                      return true;
                    }

            }

  render() {
   var getErros= this.state.errors;
     var getErross= this.state.errorss;

    return  <div className="modal fade pt-5 login" id="login" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-md modal-notify modal-primary" role="document">
          {/* Content */}
         
          <div className="modal-content ">
            {/* Header */}
            <div className="modal-header  ">
              <p className="heading lead">Let's start with Shop Smart Autos</p>
              <button type="button" className="close" data-dismiss="modal" onClick={this.hideModal} aria-label="Close">
                <span aria-hidden="true" className="white-text">×</span>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body">
            <div>
    <section id="" className="">
        {/* Pills navs */}
        <div className="container">
        <div className="col-lg-12  ">
        <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
          <li className="nav-item" role="presentation">
            <a className="nav-link btn btn-primary active" id="tab-logins" data-toggle="tab" href="#pills-login2" role="tab" aria-controls="tab-login" aria-selected="true">Login</a>
         </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link btn btn-primary" id="tab-registers" data-toggle="pill" href="#pills-register2" role="tab" aria-controls="tab-register" aria-selected="false">Register</a>
          </li>
        </ul>
        {/* Pills navs */}
        {/* Pills content */}
        <div className="tab-content p-0 ">
          <div className="tab-pane fade show active  p-0" id="pills-login2" role="tabpanel" aria-labelledby="tab-logins">
           
              
              <div className="form-outline mb-4">

                <label className="form-label p-0" htmlFor="loginName">Email</label>
                <input type="email" id="loginName" onChange={this.userEmaillogin} className="form-control" />
                  <p style={{color:'red'}}>{this.state.errorss['emaillogin']}</p>
              </div>
              {/* Password input */}
              <div className="form-outline mb-4">
              <label className="form-label p-0" htmlFor="loginPassword">Password</label>
                <input type="password" id="loginPassword" onChange={this.userPasswordlogin} className="form-control" />
                <p style={{color:'red'}}>{this.state.errorss['userpasswordlogin']}</p>
              </div>
              {/* 2 column grid layout */}
              <div className="row mb-4">
                <div className="col-md-6 d-flex justify-content-center">
                  {/* Checkbox */}
                  <div className="form-check mb-3 mb-md-0">
                    <input className="form-check-input" type="checkbox" defaultValue='1' onChange={this.Remebercheck}id="loginCheck" defaultChecked />
                    <label className="form-check-label" htmlFor="loginCheck"> Remember me </label>
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                  {/* Simple link */}
                  <a href="/forgot-password">Forgot password?</a>
                </div>
              </div>
              {/* Submit button */}
              <button  className="btn btn-primary btn-block mb-4" onClick={this.handlelogin}>Sign in</button>
              {/* Register buttons */}
               <p className="text-center">or Sign in with:</p>
              <div className="text-center mb-3">
             
 <FacebookLogin
    appId="1253233855070386"
    autoLoad={false}
    textButton=""
    fields="name,email,picture"
    onClick={this.responseFacebook}
    callback={this.responseFacebooks}
    cssClass="btn btn-primary  mx-1"
    icon="fa fa-facebook-f" />

    <GoogleLogin
    clientId="933947209959-1usqgu35vuo0sdda8p7n905i9jr686fe.apps.googleusercontent.com"
    buttonText=""
    onSuccess={this.responseGoogleLogin}
    onFailure={this.responseGoogle}
    cookiePolicy={'single_host_origin'}
    render={renderProps => (
      <button onClick={renderProps.onClick} disabled={renderProps.disabled} type="button" className="btn btn-primary  mx-1">
                  <i className="fa fa-google" />
                </button>
    )}
  />

              </div>           
          </div>
            <div className="tab-pane fade card-body p-0" id="pills-register2" role="tabpanel" aria-labelledby="tab-registers">
            


              {/* Name input */}
              <div className="form-outline mb-4">

                <label className="form-label p-0" htmlFor="registerEmail">Email</label>
                <input type="email" id="registerEmail" onChange={this.userEmail} className="form-control" />
                 <p style={{color:'red'}}>{getErros['email']}</p>
              </div>
              {/* Password input */}
              <div className="form-outline mb-4">

                <label className="form-label p-0" htmlFor="registerPassword">Password</label>
                <input type="password" id="registerPassword" onChange={this.userPassword} className="form-control" />
                 <p style={{color:'red'}}>{getErros['userpassword']}</p>
              </div>
              {/* Checkbox */}
              {/* <div className="form-check d-flex justify-content-center mb-4">
               
                <input className="form-check-input me-2" type="checkbox"  defaultChecked id="registerCheck"  aria-describedby="registerCheckHelpText" />
                 <input className="form-check-input" type="checkbox" defaultValue='1' onChange={this.Remebercheck}id="loginCheck" defaultChecked />
                    <label className="form-check-label" htmlFor="loginCheck"></label>
                      <a href="/privacy-policy">I have read and agree to the terms</a>
              </div>
              <p style={{color:'red'}}>{getErros['tc']}</p> */}
              {/* Submit button */}
              <button  className="btn btn-primary btn-block mb-3" onClick={this.UserRegsiter}>Sign up</button>
              <p className="text-center">or Sign up with:</p>
              <div className="text-center mb-3">
             
 <FacebookLogin
    appId="1253233855070386"
    autoLoad={false}
    textButton=""
    fields="name,email,picture"
    onClick={this.responseFacebook}
    callback={this.responseFacebooks}
    cssClass="btn btn-primary  mx-1"
    icon="fa fa-facebook-f" />

    <GoogleLogin
    clientId="933947209959-1usqgu35vuo0sdda8p7n905i9jr686fe.apps.googleusercontent.com"
    buttonText=""
    onSuccess={this.responseGoogleLogin}
    onFailure={this.responseGoogle}
    cookiePolicy={'single_host_origin'}
    render={renderProps => (
      <button onClick={renderProps.onClick} disabled={renderProps.disabled} type="button" className="btn btn-primary  mx-1">
                  <i className="fa fa-google" />
                </button>
    )}
  />

              </div>       
          </div>
        </div>
      
      </div>
      </div>
      </section>
      </div>
      </div>
      </div>
      </div>
      </div>

  }
}
export default  loginSignuppopup ;