import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
class Footer extends React.PureComponent {
constructor(props) {
          super(props);
            this.state = {
            error: null,
            isLoaded: false,
            Brands: [],
            valueCarType:null,
            emailNewsvarter:null,
            responseText:null,
            FirstName:null,
          LastName:null,
          email:null,
          PhoneNumber:null,
          Comment:null,
          TestDriveDate:null,
          MessageStatus:'',
          TestDriveTime:null,
          TestDriveTc:1,
          VehicleDataInfo:null,

          };}

           handleChangeEmailNewsL= handleChangeEmailNewsL=>{
        var self=this;
        handleChangeEmailNewsL.persist();
        self.setState({emailNewsvarter:handleChangeEmailNewsL.target.value});

          }
           SubscribeNewsvarter= (newsvarter)=>{
             $('#Subscribe').html('<span class="spinner-border spinner-border-sm mr-2 " role="status" aria-hidden="true"></span><span className="text-white">Subscribing..</span>').addClass('disabled');
          
            var self=this;
          $.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                     "Newsvarter":"Newsvarter",Email:self.state.emailNewsvarter,
                 },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ isLoaded: true });
                    if(getResponse.status==true)
                    {

                    self.setState({responseText:'<div class="alert alert-success">'+getResponse.message+'</div>'});

                    }else
                    {

                    self.setState({responseText:'<div class="alert alert-danger">'+getResponse.message+'</div>'});

                    }
                     $('#Subscribe').html('Subscribe').removeClass('disabled');
                    }
               );
               }



               

  render() {
    const iOSUrl = 'https://apps.apple.com/in/app/shop-smart-autos/id1550982125';
    const androidUrl = 'https://play.google.com/store/apps/details?id=com.shopsmartautos';
       
    return    <React.Fragment><footer className="footer-section">
        <div className="container-fluid">
          <div className="footer-cta">
            <div className="row">
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fa fa-map-marker" />
                  <div className="cta-text">
                    <h4>Find us</h4>
                    <span>Shop Smart Autos Inc 66 West Flagler Street - Suite 900 Miami, FL 33130</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fa fa-envelope" />
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <span>info@shopsmartautos.com</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className=" footer-social-icon">
                  <span>Follow us</span>
                  <a target="_blank" href="https://www.facebook.com/ShopSmartAutos/" ><i className="fa fa-facebook-f facebook-bg" /></a>
                  <a target="_blank" href="https://twitter.com/shopsmartautos"><i className="fa fa-twitter twitter-bg" /></a>
                  <a target="_blank" href="https://www.youtube.com/channel/UC7ArsLGgZt6Lia39I-t8Mcw"><i className="fa fa-youtube youtube-bg" /></a>
                  <a target="_blank" href="https://www.instagram.com/shopsmartautosofficial/"><i className="fa fa-instagram instagram-bg" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content">
            <div className="row">
              <div className="col-sm-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="https://shopsmartautos.com"><img data-src="../media/logo_footer.png" width="100%" height="100%" className=" lazyload img-fluid" alt="logo" /></a>
                  </div>
                  <div className="footer-text">
                    <p>Shop Smart Autos is not responsible for any errors in price or vehicle information provided to us from our dealer partners. We take every precaution to ensure the information is accurate and correct. Any questions please contact the dealer.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    {/* <li><Link to="/value-my-car" >Value My Car</Link></li> */}
      <li><Link to="/research-tool" >Research Tool</Link></li>
      {/* <li><Link to="/car-reviews" >Vehicle Reviews</Link></li> */}
      <li><Link to="/dealers" >Dealers</Link></li>
      <li><a href="https://dealers.shopsmartautos.com" target="_blank" >Dealer Log In</a></li>
 <li><Link to="/special-deals" >Special Deals</Link></li>
         <li><Link to="/new-cars" >New Cars</Link></li>
         <li><Link to="/used-cars" >Used Cars</Link></li>
         <li><Link to="/certified-cars" >Certified Cars</Link></li>
         <li><Link to="/private-party-cars" >Private Party Cars</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Subscribe</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                  </div>
                  <div className="subscribe-form">
                     <input type="text" name="email" onChange={this.handleChangeEmailNewsL}  placeholder="Type your email..."/>
                      <button className="btn btn-primary btn-rounded p-1"  onClick={this.SubscribeNewsvarter}><i className="fa fa-send" /></button>
                    <div dangerouslySetInnerHTML={{ __html:this.state.responseText}}/>
                  </div>
                  <div className="footer-text mb-25">
                    <p>Copyright © RBBP MARKETING LTD</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> </React.Fragment>;
  }
}
export default React.memo(Footer);