import React ,{lazy, Suspense} from 'react';


import LoginSignuppopup from "./loginSignuppopup";

import $ from 'jquery';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


class Header extends React.PureComponent {

constructor(props){
      super(props);
      this.state={
        access_token:null,
        user_data:null,
        status:false,
        sliders:false,

      }
      this.create_UUID = this.create_UUID.bind(this)
      this.getCookie = this.getCookie.bind(this)
      this.delete_cookie = this.delete_cookie.bind(this)
      this.UserAuth = this.UserAuth.bind(this)
      this.hideApp = this.hideApp.bind(this)
      this.logout = this.logout.bind(this)
    
      }
 UserAuth(data)
 {
   var self=this;
   self.setState({access_token:data.user_data.access_token,user_data:data.user_data});
   localStorage.setItem('user_access_token',data.user_data.access_token);
   localStorage.setItem('UserDataSSA',JSON.stringify(data.user_data));
   var getSaveStatus=localStorage.getItem('saveStatus');
   var item=JSON.parse(localStorage.getItem('saveVehicleData'));
   if(getSaveStatus){
      if($('#'+item.vin).hasClass('fa-heart'))
								{	
								$.post("https://www.shopsmartautos.com/user.php",{'unsaved':'unsaved',vehicleData:item,user_access_token:data.user_data.access_token,user_id:data.user_data.id},function(data) {
											
									$('#'+item.vin).addClass('fa-heart-o');
									$('#'+item.vin).removeClass('fa-heart');
									
									toast.success("You unsaved "+item.heading,{
      										toastId: "favrates",autoClose: 1500 
    										});
											
											})	
								}else
								{
									$.post("https://www.shopsmartautos.com/user.php",{'save_car':'save_car',vehicleData:item,user_access_token:data.user_data.access_token,user_id:data.user_data.id},function(data) {
									$('#'+item.vin).removeClass('fa-heart-o');
									$('#'+item.vin).addClass('fa-heart');
									
									toast.success("Great Choice \n You saved "+item.heading,{
      										toastId: "favrates",autoClose: 1500 
    										});
											
											})
								}
   }
 }
            logout()
                {
                      var self = this;
                var userData=localStorage.getItem('UserDataSSA');
                var user_access_token=localStorage.getItem('user_access_token');
                localStorage.removeItem('saveStatus');
                localStorage.removeItem('saveVehicleData');
                localStorage.removeItem('UserDataSSA');
                localStorage.removeItem('user_access_token');
          $.post(
                  "https://www.shopsmartautos.com/user.php",
                  {
                     "log_out":"log_out",access_token:user_access_token,user_id:userData.id
                 },
                  function(data) {
                    var getData=JSON.parse(data);
                    self.setState({user_data:null})
                    window.location.href='/login-sign-up/?logout=true';

                  }
                 )
         
                }
  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
   create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

 delete_cookie(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

componentDidMount (){
  
  var self=this;
  console.log('sdd');

var   _atrk_opts = { atrk_acct:"17BYv1FYxz20cv", domain:"shopsmartautos.com",dynamic: true};
  var as = document.createElement('script'); as.type = 'text/javascript'; as.async = true; as.src = "https://certify-js.alexametrics.com/atrk.js"; 
  var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(as, s);

  
  var cookie=self.getCookie('ssa_cookie');
  if(cookie==undefined)
  {
      var getuiid=self.create_UUID();
      document.cookie='ssa_cookie='+getuiid;
      cookie=self.getCookie('ssa_cookie');
  }else
  {
     cookie=self.getCookie('ssa_cookie');
  }
       var refrer=document.referrer;
  
      $.post("https://www.shopsmartautos.com/get_traffic.php",
      {"get_traffic":'k','url':window.location.href,"cid":cookie,'urlcoming':refrer},function (html) {
          }
        );
       var getaccesstoken= localStorage.getItem('user_access_token');
        $.post("https://www.shopsmartautos.com/user.php",
      {"user_fetch":true,'access_token':getaccesstoken},function (html) {
        var userData=JSON.parse(html);
          if(userData.error_code!=='TECHANICHAL_ISSUE' && userData.data!==null)
          {
            self.setState({user_data:userData.data.user_data,status:true});
          }else
          {
            window.location.href='/login-sign-up';
          }
          }
        );

  }
  hideApp(){
    $('.hideAPp').hide();
  }

  render() {
    if(window.location.href==='http://localhost:3000/')
{
    this.setState({sliders:true});
}

    var settings = {
      dots:true,
      infinite: true,
      speed: 3000,
      autoplay:true,
      autoplaySpeed:3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
             autoplaySpeed:3000,
            speed: 1900,
      autoplay:true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            speed: 1500,
      autoplay:true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1500,
      autoplay:true
          }
        }
      ]
    };
    return  <div>
    <div className="hideAPp">

    <div className="row clickbar">
     <div className="col-xs-1">
        <div className="close-new" onClick={this.hideApp}>
           <i className="fa fa-close" ></i></div>
        
      </div>
      <div className="col-xs-2">
        <div className="ap-logo">
            <img    width="50px" data-src="../media/shop-icon.png" className="lazyload"/></div>
        
      </div>
    <div className="col-xs-8">
        <div class="ap-cont">
          <h6> New and Used Cars</h6>
          <p>Shopsmartautos <br/> <span className="fa fa-star"></span><i>Top Rated Cars</i></p>
         
        </div>
    </div>
    <div className="col-xs-1">
          <button className="btn btn-primary ap"><a  className="text-white"href="https://shopsmartautos.page.link/download-app">Open</a></button>
     </div>
    </div>  
    </div>  
    <nav className="navbar navbar-expand-lg ">
        {/* Navbar brand */}
  
   <Link className="nav-link" to="/">
     <img   style={{'width':'170px','height':'66px'}} data-src="../media/SHOP_SMART_LOGO23-2-scaled.jpg" className="lazyload"/></Link>
        {/* Collapse button */}
         
       <button className="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent2"
    aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation"><span className="dark-blue-text"><i
        className="fa fa-bars fa-1x"></i></span></button>
        {/* Collapsible content */}
        <div className="collapse navbar-collapse" id="navbarSupportedContent2">
          {/* Links */}
          <ul className="navbar-nav mr-auto">
            {/* Multi-level dropdown */}
            <li className="nav-item">
                <Link className="nav-link" to="/special-deals">Special Deals</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/new-cars">New Cars</Link>
            </li>
           
           <li className="nav-item">
                <Link className="nav-link" to="/private-party-cars">Private Party Cars</Link>
            </li>
            <li className="nav-item ">
                  <Link id="menu" className="nav-link  text-uppercase" to="/used-cars">Used Cars</Link>
            </li>
            <li className="nav-item ">
                 <Link  className="nav-link" to="/certified-cars">
                      Certified Cars
                  </Link>
            </li>

            <li className="nav-item ">
                 {/* <Link  className="nav-link text-uppercase" to="/value-my-car">
                      Value My Car
                  </Link> */}
            </li>
            <li className="nav-item ">
                 <Link  className=" nav-link text-uppercase" to="/research-tool">
                      Research Tool
                </Link>
            </li>
            
            <li className="nav-item ">
                 <Link  className=" nav-link" to="/veterans">
                      veterans
                </Link>
            </li>
    
            <li className="nav-item dropdown">
            <Link id="menu" data-toggle="dropdown" className="nav-link dropdown-toggle text-uppercase" >Dealers</Link>
              <ul className="dropdown-menu mt-2 rounded-0  border-0 z-depth-1 p-2">
                <li className="dropdown-item dropdown-submenu p-0">
                  <Link id="menu"  className=" text-uppercase text-black w-100" to="/dealers">Dealers</Link>
                </li>
                <li className="dropdown-item dropdown-submenu p-0">
                  <Link id="menu"  className=" text-uppercase text-black w-100" to="/new-car-dealers">New Car Dealers</Link>
                </li>
                <li className="dropdown-item dropdown-submenu p-0">
                  <Link id="menu"  className=" text-uppercase text-black w-100" to="/independent-dealer">Independent Dealer</Link>
                </li>
                <li className="dropdown-item dropdown-submenu p-0">
                  <a className=" text-uppercase text-black w-100" target="_blank" href="https://dashboard.rbbpmarketingltd.com/">
                    Dealers Login
                  </a>
                </li>

              </ul>
               
           </li>
           <li className="nav-item ">
                 <Link  className="nav-link" to="/sell-my-car">
                      Sell My Car
                </Link>
            </li>
           
          </ul>
      <ul className="navbar-nav mr-auto">
                <li className="nav-item dropdown">
          <Link id="menu" data-toggle="dropdown" className="nav-link dropdown-toggle text-uppercase" ><i className="fa fa-user"></i> {
            	this.state.status==true  && this.state.user_data !==null && this.state.user_data.user_name!==null
				  ?
				 	this.state.user_data.user_name.slice(0,10)
				  :
				  this.state.status==true  && this.state.user_data!==null  && this.state.user_data.user_email!==null
				  ?
				  this.state.user_data.user_email.slice(0,10)
				  :
				   'Account'
				}</Link>
              <ul className="dropdown-menu mt-2 rounded-0  border-0 z-depth-1 p-2">
              {this.state.status && this.state.user_data !==null ?
                <div>
                <li className="dropdown-item dropdown-submenu p-0">
                  <a id="menu"  className=" text-uppercase text-black w-100" href="/user-dashboard/overview">My Account</a>
                </li>
                <li className="dropdown-item dropdown-submenu p-0">
                  <Link id="menu"  className=" text-uppercase text-black w-100" onClick={this.logout} to="/log-out">Log out</Link>
                </li>
                </div>
                :
                <li className="dropdown-item dropdown-submenu p-0">
                  <Link id="menu"  className=" text-uppercase text-black w-100" to="/login-sign-up">Sign in / Register</Link>
                </li>
                }
              </ul>
               
                </li>
                </ul>   
            
  </div>
      </nav></div>;
}
}
export default Header;