import React, {lazy, Suspense} from 'react';
import {Redirect, Switch, Route,BrowserRouter as Router } from "react-router-dom";
import Maincontent from '../component/Maincontent';
import Header from '../component/Header';
const Vehicles = lazy(() => import("../component/Vehicles"))
const Valuemycar = lazy(() => import("../component/Valuemycar"))
const ResearchTool = lazy(() => import("../component/ResearchTool"))
const carreviews = lazy(() => import("../component/carreviews"))
const newcars = lazy(()=>import("../component/newcars"));
const usedcars = lazy(() => import("../component/usedcars"));
const dealers = lazy(() => import("../component/dealers"));

const cardetails = lazy(() => import("../component/cardetails"));
const comparecar = lazy(() => import( "../component/comparecar"));
const carreviewsresults = lazy(() => import("../component/carreviewsresults"))
const certifiedcars = lazy(() => import("../component/certifiedcars"))
const carview = lazy(() => import("../component/carview"));
const ads = lazy(() => import("../component/ads"));
const chat = lazy(()=> import("../component/chat"));
const loginsignup = lazy(() => import("../component/loginsignup"))
const logout = lazy(() => import("../component/logout"));
const financelease = lazy(() => import("../component/financelease"));
const vinhistory= lazy(() => import("../component/vinhistory"));
const dealerview = lazy(() => import("../component/dealerview"));
const dealershow = lazy(() => import("../component/dealershow"))
const userprofile = lazy(() => import("../component/userprofile"))
const savedcars = lazy(() => import("../component/savedcars"))
const post = lazy(() => import("../component/post"))
const blogs = lazy(() => import("../component/blogs"));
const privacypolicy = lazy(() => import("../component/privacypolicy"))
const admindashboard = lazy(() => import("../component/admindashboard"));
const dealerinventry = lazy(() => import("../component/dealerinventry"));
const newpassword = lazy(() => import("../component/newpassword"));
const verifypassword = lazy(() => import("../component/verifypassword"));
const forgotpassword = lazy(() => import("../component/forgotpassword"));
const verifyemail = lazy(() => import("../component/verifyemail"));
const independentdealer = lazy(() => import("../component/independentdealer"));
const newcardealers  = lazy(() => import("../component/newcardealers"));
const privatepartycarsview = lazy(() => import("../component/privatepartycarsview"))
const privatepartycars = lazy(() => import("../component/privatepartycars"))
const military = lazy(() => import("../component/military"))
const carbuild = lazy(() => import("../component/carbuild"))
const sellmycar = lazy(() => import("../component/sellmycar"))
const shipmycar = lazy(() => import("../component/shipmycar"))

class Routes extends React.Component {

  render() {
  var url= window.location.pathname;
   var chaturl= window.location.pathname.split('/');
  
        var header = chaturl[1] === 'chat' || chaturl[1] == 'user-dashboard'  ? null : <Header/> 
if(chaturl[1] == 'user-dashboard')
{
 var access_token= localStorage.getItem('user_access_token');
 if (access_token==null || access_token==undefined || access_token=='' )
 {
   
    window.location.href='/login-sign-up';
  }
}
     

  	return(<Router>{header}   
      <Suspense fallback={<div></div>}>
      <Switch>
        <Route exact path="/" component={Maincontent} />
        <Route path="/special-deals" component={Vehicles} />
        <Route path="/ads" component={ads} />
        <Route path="/value-my-car" component={Valuemycar} />
        <Route path="/research-tool" component={ResearchTool} />
        <Route path="/car-reviews" component={Maincontent} />
        <Route path="/sell-my-car" component={sellmycar} />
        <Route path="/ship-my-car" component={shipmycar} />
        <Route path="/new-cars" component={newcars} />
        <Route path="/used-cars" component={usedcars} />
        <Route path="/dealers" component={dealers} />
        <Route path="/car-details" component={cardetails} />
        <Route path="/compare-car" component={comparecar} />
        <Route path="/car-reviews-results" component={carreviewsresults} />
        <Route path="/certified-cars" component={certifiedcars} />
        <Route path="/post/:slug" component={post} />
        <Route path="/car-view" component={carview} />
        <Route path="/feature-blog" component={blogs} />
        <Route path="/dealer-inventry" component={dealerinventry} />
        <Route path="/dealer-view" component={dealerview} />
        <Route path="/chat/*" component={chat} />
        <Route path="/vin-history" component={vinhistory} />
        <Route path="/lease" component={financelease} />
        <Route path="/private-party-cars" component={privatepartycars} />
        <Route path="/private-party-cars-view" component={privatepartycarsview} />
        <Route path="/dealer-show" component={dealershow} />
        <Route path="/new-car-dealers" component={newcardealers} />
        <Route path="/independent-dealer" component={independentdealer} />
        <Route path="/privacy-policy" component={Maincontent} />
        <Route path="/login-sign-up" component={loginsignup} />
        <Route path="/log-out" component={logout} />
        <Route path="/user-dashboard/overview" component={savedcars} />
        <Route path="/verify-email" component={verifyemail} />
        <Route path="/forgot-password" component={forgotpassword} />
        <Route path="/verify-password" component={verifypassword} />
        <Route path="/new-password" component={newpassword} />
        <Route path="/user-dashboard/user-profile" component={userprofile}/>
        <Route path="/veterans" component={military}/>
        <Route path="/carbuild" component={carbuild}/>
          
            
        <Route path="/user-dashboard/log-out" component={logout} />
        </Switch>
        </Suspense>
        </Router>
      );
  }
}
export default Routes;