import React from 'react';
import loadable from '@loadable/component'

import $ from 'jquery';
const Select = loadable(() => import('react-select'));
class Searchbarhome extends React.PureComponent {
		constructor(props){
			super(props)
			this.state ={
								latitude: null,
								longitude: null,
								isLoaded:false,
								Makes:null,
								Models:null,
								Years:null,
								MakeSelected:null,
								ModelSelected:null,
								zipcode:null,
								vehicleClass:'new',
								makeerror:null,
								modelerror:null,
						}
						    
		}

		componentDidMount (){
			// Get User current position
			var self = this;
			navigator.geolocation.getCurrentPosition(function(position) {      
			self.setState({latitude: position.coords.latitude,longitude: position.coords.longitude,});
				$('#homesearchbutton').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Make Loading').addClass('disabled');

		   		$.post(

                  "https://www.shopsmartautos.com/react_apibb.php",
                  {

                  	"LoadMake":"LoadMake",latitude: self.state.latitude,longitude: self.state.longitude,vehicleClass:self.state.vehicleClass
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                  if(getResponse!==null)
                  {
                  	
                  	self.setState({ Makes: getResponse });
                    self.setState({ isLoaded: true });
                    self.setState({ makeerror: null });

                  }else
                  {
                  	self.setState({ makeerror: 'Currently, We are not available to this location' });
                    self.setState({ isLoaded: true });
                  }
                    
                  }
               ); 
		   		
				// Get the zipcode according to current location
				$.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                  	"homepagesearch":"homepagesearch",lat: self.state.latitude,lng: self.state.longitude
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ zipcode: getResponse.zipcode });
                    self.setState({ isLoaded: true });
                     $('#homesearchbutton').html('Search').removeClass('disabled');
                  }
               ); 




			}, (error)=>{
				// On denied position
					var self = this;
					 $('#homesearchbutton').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Make Loading').addClass('disabled');
		   		$.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                  	"LoadMake":"LoadMake",latitude:40.94623,longitude: -73.069777,vehicleClass:self.state.vehicleClass
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);

                  if(getResponse!==null)
                  {
                  	
                  	self.setState({ Makes: getResponse });
                    self.setState({ isLoaded: true });
                    self.setState({ makeerror: null });

                  }else
                  {
                  	self.setState({ makeerror: 'Currently, We are not available to this location' });
                    self.setState({ isLoaded: true });
                  }
                    $('#homesearchbutton').html('Search').removeClass('disabled');
                  }
               );
			this.setState  ({
								latitude:40.94623,
								longitude: -73.069777,
								isLoaded:true,
								Makes:null,

							});
			
			// Get the zipcode according to current location
				$.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                  	"homepagesearch":"homepagesearch",lat: self.state.latitude,lng: self.state.longitude
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ zipcode: getResponse.zipcode });
                    self.setState({ isLoaded: true });
                  }
               );
			});

		}
		
	// get model on user make selection start
	handleChange = selectedOption => {
				var self = this;
				 $('#homesearchbutton').html('<span class="spinner-border spinner-border-sm mr-2 " role="status" aria-hidden="true"></span><span className="text-white">Models Loading</span>').addClass('disabled');
		   		$.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                  	"LoadModel":"LoadModel",latitude: self.state.latitude,longitude: self.state.longitude,make:selectedOption.value,vehicleClass:self.state.vehicleClass
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                  if(getResponse!==null)
                  {
                  	
                    self.setState({ makeerror: null });
					self.setState({ Models: getResponse });
                    self.setState({ isLoaded: true });
                    self.setState({ selectedMake: selectedOption.value });
                  }else
                  {
                  	self.setState({ modelerror: 'Models are not available to this make' });
                    self.setState({ isLoaded: true });
                  }
                    
                    $('#homesearchbutton').html('Search').removeClass('disabled');
                  }
               ); 

			}
	// get model on user make selection end

	// get model on user make selection start
	handleVehcileClass= getClassValue=>{
		var self = this;
		getClassValue.persist();
		// change form action url according user input
		$('#make').val('');
		$('#model').val('');
		if (getClassValue.target.value=='new') {
			
			self.setState({ vehicleClass: 'new' });
			$.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                  	"LoadMake":"LoadMake",latitude: self.state.latitude,longitude: self.state.longitude,vehicleClass:'new'
   					},
                  function(data) {
                  	  var getResponse=JSON.parse(data);
                  	if(getResponse!==null)
                  {
                  	
                    self.setState({ makeerror: null });
					self.setState({ Makes: getResponse });
                    self.setState({ isLoaded: true });
                  }else
                  {
                  	self.setState({ makeerror: 'Currently, We are not available to this location' });
                    self.setState({ isLoaded: true });
                  }
                
                  }
               );
			$('#search_by_make').attr('action','/new-cars');
		}else if(getClassValue.target.value=='used')
		{	
			self.setState({ vehicleClass: 'used' });
			$.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                  	"LoadMake":"LoadMake",latitude: self.state.latitude,longitude: self.state.longitude,vehicleClass:'used'
   					},
                  function(data) {
                  var getResponse=JSON.parse(data);
                  	if(getResponse!==null)
                  {
                  	
                    self.setState({ makeerror: null });
					self.setState({ Makes: getResponse });
                    self.setState({ isLoaded: true });
                  }else
                  {
                  	self.setState({ makeerror: 'Currently, We are not available to this location' });
                    self.setState({ isLoaded: true });
                  }
                
                  }
               );
			$('#search_by_make').attr('action','/used-cars');
		}else if(getClassValue.target.value=='certified')
		{	
			
			self.setState({ vehicleClass: 'used' });
			$.post(
                  "https://www.shopsmartautos.com/react_apibb.php",
                  {
                  	"LoadMake":"LoadMake",latitude: self.state.latitude,longitude: self.state.longitude,vehicleClass:'certified'
   					},
                  function(data) {
                  var getResponse=JSON.parse(data);
                  	if(getResponse!==null)
                  {
                  	
                    self.setState({ makeerror: null });
					self.setState({ Makes: getResponse });
                    self.setState({ isLoaded: true });
                  }else
                  {
                  	self.setState({ makeerror: 'Currently, We are not available to this location' });
                    self.setState({ isLoaded: true });
                  }
                
                  }
               );
			$('#search_by_make').attr('action','/certified-cars');
		}

	}

	handleSubmit= data=>{
console.log(data);
	}
	
	// get model on user make selection end

  render() 
  {
  		const  isLoaded  = this.state.isLoaded;
  		const  Makes  = this.state.Makes;
  		const  zipcode  = this.state.zipcode;
  		const  selectedMake  = this.state.selectedMake;
  		const  Models  = this.state.Models;
  		const { selectedOption } = this.state;
  	 	const { selectedModel } = this.state;
  	 	const { selectedYear } = this.state;
  		if (isLoaded) {
  			//console.log(this.state);

  		}else
  		{
  			//console.log(this.state);
  		}
  return     <React.Fragment> <section className="container">

			  <h4 className=" ">Find your right car</h4>
				<form id="search_by_make" action="/new-cars/" method="get" onSubmit={this.handleSubmit} noValidate >
					<div className="row">
						<div className="col-lg-4">
								
								 <Select id="make" name="make" placeholder="Select Make"
	       								 value={selectedOption}
	        								onChange={this.handleChange}
	        								options={Makes} required/>
	        					<small style={{'color':'red'}}>{this.state.makeerror !== null ? this.state.makeerror : ''}</small>
						
							</div>
							<div className="col-lg-4">
							 
								 <Select id="model" name="model" className="selectMake" placeholder="Select Models"
	       								 value={selectedModel}
	        								onChange={this.modelChange}
	        								options={Models}required/>
	        				<small style={{'color':'red'}}>{this.state.modelerror !== null ? this.state.modelerror : ''}</small>
							
							</div>
							<div className="col-lg-4">
								
								 <input type="text"  placeholder="Zipcode" className="form-control" name="zipcode" defaultValue={this.state.zipcode}/>
								 
							
							</div>
						
						<div className="col-lg-12">
							<div className=" custom-control custom-radio custom-control-inline">
					          <input type="radio" className="" checked id="defaultInline1" name="car_type" onChange={this.handleVehcileClass} value="new"/>
					          <label className="control-label" htmlFor="defaultInline1">New car</label>
        </div>
        {/* Default inline 2*/}
				        <div className="custom-radio custom-control custom-control-inline">
				          <input type="radio" className="" name="car_type" id="defaultInline2" onChange={this.handleVehcileClass} value="used"/>
				          <label className="control-label" htmlFor="defaultInline2">Used car</label>
				        </div>
				        {/* Default inline 3*/}
				        <div className="custom-control custom-radio custom-control-inline ">
				          <input type="radio" className="" name="car_type" id="defaultInline3" onChange={this.handleVehcileClass} value="certified"/>
				          <label className="control-label" htmlFor="defaultInline3">Certified</label>
				        </div>
							</div>
								<button className="btn btn-warning btn-rounded custom-color" id="homesearchbutton" >Search</button>
						

						</div>
						
				</form>
	</section>  </React.Fragment>;
  }
}
export default React.memo(Searchbarhome);